import React from "react";
import FestivalCard from "./FestivalCard";

const FestivalList = ({ festivals, onEdit, onDelete }) => {
  return (
    <div>
      {festivals.map((festival) => (
        <FestivalCard
          key={festival.id}
          festival={festival}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

export default FestivalList;
