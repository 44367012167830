import React, { useEffect, useState } from "react";
import { FaEye, FaFileSignature } from "react-icons/fa";
import "./FestivalHeader.css";

const FestivalHeader = ({ festivalDetails }) => {
  const [views, setViews] = useState(0);
  const [registrations, setRegistrations] = useState(0);

  useEffect(() => {
    if (festivalDetails) {
      let totalViews =
        festivalDetails.opportunities.length > 0
          ? festivalDetails.opportunities.reduce(
              (total, item) => total + (item.views || 0),
              0
            )
          : 0;
      const totalRegistrations =
        festivalDetails.opportunities.length > 0
          ? festivalDetails.opportunities.reduce(
              (total, item) => total + (item.register_count || 0),
              0
            )
          : 0;

      // Add the views of the festival
      if (festivalDetails.views) totalViews += festivalDetails.views;

      setViews(totalViews);
      setRegistrations(totalRegistrations);
    }
  }, [festivalDetails]);

  return (
    <div className="header-section">
      {/* Left Section with Logo and Event Info */}
      <div className="container header-left">
        <img
          src={festivalDetails?.logo}
          alt="Festival Logo"
          className="header-logo"
        />
        <div className="header-content">
          <h2>{festivalDetails?.name}</h2>
          <p className="header-info">
            <span>
              <strong> Hosted by:</strong>{" "}
              <a
                href={festivalDetails?.website}
                target="_blank"
                rel="noreferrer"
              >
                {festivalDetails?.organization}
              </a>
            </span>
            <strong className="header-info-divider">
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            </strong>
            <span>
              <strong>Eligibility:</strong> {festivalDetails?.eligibility}
            </span>
          </p>

          <div className="header-stats">
            <span>
              <FaEye color="#e3054b" />{" "}
              <b className="big-screen-only">Views:</b> {views}
            </span>
            <span>
              <b>|</b>
            </span>
            <span>
              <FaFileSignature color="#3140f7" />{" "}
              <b className="big-screen-only">Registrations:</b> {registrations}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FestivalHeader;
