import React from "react";
import LightGallery from "lightgallery/react";
import "./ImageGallery.css";

// Import LightGallery styles and plugins
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";

// Import required LightGallery plugins
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const ImageGallery = ({ images }) => {
  return (
    <LightGallery
      speed={500}
      plugins={[lgThumbnail, lgZoom]}
      elementClassNames="custom-gallery"
      mousewheel={true}
      closable={true}
      showCloseIcon={true}
    >
      {images.map((img, index) => (
        <a
          key={index}
          href={img}
          className="gallery-item"
          style={{
            display: "inline-block",
            margin: "10px",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            src={img}
            alt={`Gallery item ${index + 1}`}
            style={{
              width: "300px",
              height: "200px",
              objectFit: "cover",
              display: "block",
            }}
          />
        </a>
      ))}
    </LightGallery>
  );
};

export default ImageGallery;
