import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FaFileDownload, FaFileUpload } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import {
  getSignedUrls,
  uploadFileWithProgress,
} from "../../services/uploadService.js";
import { trimObjectValues } from "../CommonFiles/Utils/ObjectUtils.js";

function DynamicSubmissionUserForm(props) {
  const [uploading, setUploading] = useState(false);
  const [hasSubmissionChanged, setHasSubmissionChanged] = useState([]);
  const [errors, setErrors] = useState({});
  const [submissionData, setSubmissionData] = useState(
    props.submissionData ??
      props.submissionSettings.submissionFormSettings.reduce((acc, field) => {
        acc[field.fieldName] = "";
        return acc;
      }, {})
  );
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadCount, setUploadCount] = useState(0);

  // This is to open the links (if any added by user) in the tnc field in a new tab
  useEffect(() => {
    // Find all .tnc-text containers
    const containers = document.querySelectorAll(".tnc-text");
    containers.forEach((container) => {
      const links = container.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      });
    });
  }, [submissionData]);

  const renderField = (field) => {
    let fieldValue = submissionData[field.fieldName] || "";

    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
        return (
          <input
            className="form-control"
            type={field.fieldType}
            name={field.fieldName}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field?.rules)}
            value={fieldValue}
            onKeyDown={(e) =>
              field.fieldType === "number" &&
              (e.key === "ArrowUp" || e.key === "ArrowDown") &&
              e.preventDefault()
            }
          />
        );
      case "radio":
        const longestOptionLength = Math.max(
          ...field.options.map((item) => item.length)
        );
        return (
          <div
            className={`radio-field ${longestOptionLength > 25 && field.options.length > 2 && "column"}`}
          >
            {field.options.map((option, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name={field.fieldName}
                  value={option}
                  onChange={(e) => handleChange(e, field?.rules)}
                  checked={fieldValue === option}
                />
                {option}
              </label>
            ))}
          </div>
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            name={field.fieldName}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field?.rules)}
            value={fieldValue}
            maxLength={field.rules?.find((rule) => rule.maxLength)?.maxLength}
          />
        );
      case "checkbox":
        return (
          <div className="custom-field-checkbox-container">
            {field.options.map((option, index) => (
              <label key={index} className="custom-field-checkbox-item">
                <input
                  type="checkbox"
                  name={field.fieldName}
                  value={option}
                  className="form-control custom-field-checkbox"
                  onChange={(e) => handleChange(e, field?.rules)}
                  checked={fieldValue && fieldValue.includes(option)}
                />
                {field.isTnCField ? (
                  <div className="tnc-text">
                    <div dangerouslySetInnerHTML={{ __html: option }} />
                  </div>
                ) : (
                  option
                )}
              </label>
            ))}
          </div>
        );

      case "select":
        return (
          <select
            className="form-control"
            name={field.fieldName}
            onChange={(e) => handleChange(e, field?.rules)}
            value={fieldValue}
          >
            <option value="">Select an option</option>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );

      case "file":
        if (
          props.submissionData &&
          !hasSubmissionChanged.find(
            (fieldName) => fieldName === field.fieldName
          ) &&
          submissionData[field.fieldName] &&
          submissionData[field.fieldName].length > 0
        ) {
          const uploadedFileUrl = submissionData[field.fieldName];

          return (
            <div className="uploaded-file-container">
              <a
                href={uploadedFileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="file-name"
                style={{
                  marginRight: "10px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Your earlier submission available here.
              </a>
              <span className="action-buttons">
                <button onClick={() => window.open(uploadedFileUrl, "_blank")}>
                  <FaFileDownload title="Download" size={21} color="#ffffff" />{" "}
                  Previous Submission
                </button>
                <button onClick={() => handleDelete(field.fieldName)}>
                  <FaFileUpload title="Delete" size={25} color="#ffffff" />{" "}
                  Change Submission
                </button>
              </span>
            </div>
          );
        } else {
          return (
            <input
              className="form-control"
              type="file"
              name={field.fieldName}
              onChange={(e) => handleChange(e, field?.rules)}
            />
          );
        }
      default:
        return null;
    }
  };

  const handleDelete = (fieldName) => {
    setHasSubmissionChanged([...hasSubmissionChanged, fieldName]);
    setSubmissionData((prevData) => ({
      ...prevData,
      [fieldName]: "",
    }));
  };

  const handleChange = (e, rules) => {
    const { name, value, type, checked, files } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setSubmissionData((prevData) => {
      if (type === "checkbox") {
        const newValue = checked
          ? prevData[name]
            ? `${prevData[name]}, ${value}`
            : value
          : prevData[name]
              .split(", ")
              .filter((v) => v !== value)
              .join(", ");

        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));

        return { ...prevData, [name]: newValue };
      } else if (type === "file" && files[0]) {
        const maxFileSize = rules?.find(
          (rule) => rule.maxFileSize
        )?.maxFileSize;
        const allowedFileTypes = rules?.find(
          (rule) => rule.allowedFileTypes
        )?.allowedFileTypes;

        if (maxFileSize && files[0].size > maxFileSize * 1024 * 1024) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: `File size exceeds the limit of ${maxFileSize} MB`,
          }));
          return prevData;
        } else {
          if (
            allowedFileTypes.length > 0 &&
            !allowedFileTypes.find((t) => t.value === "any") &&
            !isFileTypeAllowed(files[0].type, allowedFileTypes)
          ) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: `File type is not allowed`,
            }));
            return prevData;
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: "",
            }));

            const filteredSelectedFiles = [...selectedFiles].filter(
              (file) => file.fieldName !== name
            );
            setSelectedFiles([
              ...filteredSelectedFiles,
              { fieldName: name, file: files[0] },
            ]);
            return prevData;
          }
        }
      } else if (type === "number") {
        if (/^[0-9]*$/.test(value)) {
          const maxLengthRule = rules?.find(
            (rule) => rule.maxLength !== undefined
          );
          const minLengthRule = rules?.find(
            (rule) => rule.minLength !== undefined
          );

          if (minLengthRule && value.length < minLengthRule.minLength) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: `Please enter a value with ${minLengthRule.minLength} digits`,
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: "",
            }));
          }

          if (maxLengthRule && value.length > maxLengthRule.maxLength) {
            return prevData;
          } else {
            return { ...prevData, [name]: value };
          }
        } else {
          return prevData;
        }
      } else if (type === "email") {
        if (!/\S+@\S+\.\S+/.test(value.toLowerCase())) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Please enter a valid email address",
          }));
        }

        return { ...prevData, [name]: value.toLowerCase() };
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        return { ...prevData, [name]: value };
      }
    });

    if (rules && type !== "file") {
      const maxLengthRule = rules.find((rule) => rule.maxLength !== undefined);
      const minLengthRule = rules.find((rule) => rule.minLength !== undefined);

      if (maxLengthRule && value.length > maxLengthRule.maxLength) {
        return;
      }

      if (minLengthRule && value.length < minLengthRule.minLength) {
        setSubmissionData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `Minimum length required for this field is ${minLengthRule.minLength}`,
        }));
      } else {
        setSubmissionData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const uploadFile = async (file, fieldName) => {
    // Generate a new file name with a timestamp
    const newFileName = `${moment().valueOf()}-${file.name}`;
    const newFile = new File([file], newFileName, { type: file.type });

    try {
      // Get the signed URL for the new file name
      const signedUrl = await getSignedUrls([{ fileName: newFile.name }]);

      // Upload the file using the signed URL
      await uploadFileWithProgress(signedUrl[0].signedUploadURL, newFile);

      const fileUrl = signedUrl.find(
        (s) => s.fileName === newFile.name
      ).fileURL;

      setUploadCount((prevCount) => prevCount + 1);

      return { fieldName, fileUrl };
    } catch (error) {
      console.error("File upload failed:", error);
      return { fieldName, error: "An error occurred while uploading the file" };
    }
  };

  const handleSubmitClick = async () => {
    // check if the round has ended or not
    if (props.roundEndTime.add(59, "seconds").isBefore(moment())) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Deadline is over, better luck next time!",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });

      return;
    }

    // Remove file upload errors from error list
    const errorsWithoutFiles = { ...errors };

    Object.keys(errorsWithoutFiles).forEach((key) => {
      if (
        errorsWithoutFiles[key] === "An error occurred while uploading the file"
      ) {
        delete errorsWithoutFiles[key];
      }
    });

    setErrors(errorsWithoutFiles);

    let cleanedSubmissionData = trimObjectValues(submissionData);
    setSubmissionData(cleanedSubmissionData);

    // Check if there are any errors in the errors object
    if (Object.values(errorsWithoutFiles).some((error) => error !== "")) {
      return;
    }

    // Collect all mandatory fields
    const requiredFields = props.submissionSettings.submissionFormSettings
      .filter((field) => field.isMandatory)
      .map((field) => field.fieldName);

    const fieldsWithError = [];

    requiredFields.forEach((fieldName) => {
      if (
        (!cleanedSubmissionData[fieldName] ||
          cleanedSubmissionData[fieldName] === "") &&
        !selectedFiles.find((f) => f.fieldName === fieldName)
      ) {
        fieldsWithError.push(fieldName);
      }
    });

    if (fieldsWithError.length > 0) {
      // Construct new errors object
      const newErrors = fieldsWithError.reduce((acc, fieldName) => {
        acc[fieldName] = "Please fill out this field";
        return acc;
      }, {});
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
      return;
    }

    if (selectedFiles.length > 0) {
      try {
        setUploading(true);
        // Upload all files and collect results
        const uploadResults = await Promise.all(
          selectedFiles.map(({ file, fieldName }) =>
            uploadFile(file, fieldName)
          )
        );

        // Separate successful uploads from errors
        const successfulUploads = uploadResults.filter(
          (result) => !result.error
        );
        const failedUploads = uploadResults.filter((result) => result.error);

        // Handle errors, if any
        if (failedUploads.length > 0) {
          const newErrors = failedUploads.reduce(
            (acc, { fieldName, error }) => {
              acc[fieldName] = error;
              return acc;
            },
            {}
          );
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...newErrors,
          }));
          setUploading(false);
          setUploadCount(0);
          return; // Exit if there were upload errors
        }

        // Update submissionData with the successful file URLs
        const newSubmissionData = successfulUploads.reduce(
          (acc, { fieldName, fileUrl }) => {
            acc[fieldName] = fileUrl;
            return acc;
          },
          {}
        );
        setSubmissionData((prevData) => ({
          ...prevData,
          ...newSubmissionData,
        }));

        setUploading(false);

        // Clear selected files
        setSelectedFiles([]);
        setUploadCount(0);

        // Forward cleaned submission data to the parent component
        cleanedSubmissionData = {
          ...cleanedSubmissionData,
          ...newSubmissionData,
        };
        setSubmissionData(cleanedSubmissionData);
        props.onSubmit(cleanedSubmissionData);
      } catch (error) {
        console.error("File upload failed:", error);
        // Handle upload error, e.g., display a message to the user
      }
    } else {
      // Forward cleaned submission data to the parent component
      props.onSubmit(cleanedSubmissionData);
    }
  };

  function isFileTypeAllowed(fileType, allowedTypes) {
    // Flatten the values into a single array of MIME types
    const allowedMimeTypes = allowedTypes.flatMap((type) =>
      type.value.split(",")
    );

    // Check if the given file type is in the allowed list
    return allowedMimeTypes.includes(fileType);
  }

  return (
    <Modal
      size="lg"
      scrollable={true}
      show={props.isOpen}
      onHide={props.onClose}
      className="dynamic-submission-user-modal"
      backdrop="static"
      keyboard={false}
    >
      {props.submissionSettings && (
        <div className="dynamic-submission-form">
          <div className="dynamic-submission-form-content">
            {!uploading && (
              <>
                <h5>Submission Guidelines</h5>
                {props.submissionSettings &&
                  props.submissionSettings.guidelines && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: props.submissionSettings.guidelines,
                      }}
                    ></p>
                  )}

                <div
                  className="mb-2"
                  style={{
                    background: "#fafafa",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                >
                  {props.submissionSettings.submissionFormSettings &&
                    props.submissionSettings.submissionFormSettings.length >
                      0 &&
                    props.submissionSettings.submissionFormSettings.map(
                      (item) => (
                        <div className="input-field">
                          <div className="field-label-container">
                            <label>
                              {item.label}
                              {item.isMandatory && (
                                <span className="required-mark"> *</span>
                              )}
                            </label>
                          </div>
                          {renderField(item)}
                          {errors[item.fieldName] && (
                            <span className="required-mark">
                              {errors[item.fieldName]}
                            </span>
                          )}
                        </div>
                      )
                    )}
                </div>
              </>
            )}

            {uploading && (
              <div className="uploading-container">
                <Spinner
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  style={{ width: "3rem", height: "3rem" }}
                />
                <br />
                <p className="uploading-count">
                  Uploading {uploadCount + 1} of {selectedFiles.length}{" "}
                  {selectedFiles.length > 1 ? "files" : "file"}
                </p>

                <p className="uploading-info">
                  Thank you for your submission! We’re currently processing your
                  submission. This may take few moments. Please keep this window
                  open, and do not navigate away to ensure everything uploads
                  smoothly. You’ll be notified once your submission is complete.
                </p>
              </div>
            )}
          </div>

          <div>
            {props.submissionData && (
              <button
                className="btn menubtn submitbtn "
                onClick={() => props.onDeleteSubmission()}
                disabled={uploading}
              >
                <MdDelete /> <b>Delete Submission</b>
              </button>
            )}

            <div className="float-right">
              <button
                className="btn dynamic-submission-form-close"
                disabled={uploading}
                onClick={() => props.onClose()}
              >
                Close
              </button>
              <button
                className="btn menubtn submitbtn "
                onClick={handleSubmitClick}
                disabled={uploading}
              >
                {uploading ? "Please wait ..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default DynamicSubmissionUserForm;
