import React from "react";
import { Link } from "react-router-dom";
import Modal, { useModal } from "../../CommonFiles/UI Elements/Modal/Modal";
import "./FestivalCard.css";

const FestivalCard = ({ festival, onEdit, onDelete }) => {
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <React.Fragment>
      <div className="row registerbox">
        <div className="col-md-2">
          <div>
            <img src={festival.logo} alt="Festival Logo" className="imgsize" />
          </div>
        </div>
        <div className="col-md-6">
          <p className="myhosting-titiles">
            <Link
              to={`/festival/${festival.slug !== "" ? festival.slug : festival.id}`}
            >
              {festival.name}
            </Link>
          </p>
          <div className="displayflex1 mt-3">
            <p style={{ fontSize: "16px" }}>
              <strong>Hosted By: </strong>
              <a
                href={festival.website ?? "#"}
                target="_blank"
                rel="noreferrer"
              >
                {festival.organization}
              </a>
            </p>
            <p style={{ fontSize: "16px" }}>
              <strong>Eligibility: </strong>
              {festival.eligibility}
            </p>
            <p style={{ fontSize: "16px" }}>
              <strong>Opportunities: </strong>
              {festival.opportunities?.length ?? 0}{" "}
              <i
                className="fa fa-info-circle"
                style={{ color: "red", cursor: "pointer" }}
                aria-hidden="true"
                onClick={openModal}
              ></i>
            </p>
          </div>
        </div>
        <div className="col-md-4 hostingcls talignright">
          <div className="d-flex justify-content-end">
            <button
              className="btn menubtn actionBtn transparentbtn"
              onClick={() => onEdit(festival)}
            >
              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
              &nbsp;Edit Festival
            </button>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn menubtn actionBtn transparentbtn"
              onClick={() => onDelete(festival)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete
              Festival
            </button>
          </div>
        </div>
      </div>

      {/* Opportunities List Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Opportunities"
        width="700px"
      >
        {festival.opportunities?.length > 0 ? (
          <table className="opportunities-table">
            <thead>
              <tr>
                <th>Opportunity Name</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {festival.opportunities?.map((opportunity) => (
                <tr key={opportunity.id}>
                  <td>
                    <a
                      href={`${window.location.origin}/e/${opportunity.slug}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {opportunity.title}
                    </a>
                  </td>
                  <td>{opportunity.start_date}</td>
                  <td>{opportunity.end_date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-opportunities">No opportunities available</p>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default FestivalCard;
