import axios from 'axios';

const USER_TOKEN = localStorage.getItem("token");
const AuthStr = 'Bearer '.concat(USER_TOKEN);

const axiosInstance = axios.create({
  headers: {
    'Authorization': AuthStr
  }
});

export default axiosInstance;