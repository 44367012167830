import { Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FaArrowRight, FaEdit, FaSave } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../..//CommonFiles/config.json";
import CreateFestivalModal from "./CreateFestivalModal";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const today = new Date().toISOString().split("T")[0];
const StepOne = (props) => {
  const [inputValue, setInputValue] = useState({
    // visibility: "",
    category: "",
    title: "",
    website: "",
    organization: "",
    festival: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    location: "",
    region: "",
  });

  const [error, setError] = useState([]);
  const [editData, setEditData] = useState([]);
  const [saveSucess, setSaveSucess] = useState("");
  const [festivalsList, setFestivalsList] = useState([]);
  const [suggestedFestivalList, setSuggestedFestivalList] = useState([]);
  const [selectedFestival, setSelectedFestival] = useState(undefined);
  const [showCreateFestivalModal, setShowCreateFestivalModal] = useState(false);
  const [editingFestival, setEditingFestival] = useState(null);

  useEffect(() => {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);

    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.data;
          var oldData = {
            category: alldata.category,
            title: alldata.title,
            website: alldata.website,
            organization: alldata.organization,
            festival: alldata.festival,
            start_date: alldata.start_date,
            start_time: alldata.start_time,
            end_date: alldata.end_date,
            end_time: alldata.end_time,
            location: alldata.location,
            region: alldata.region,
          };
          setInputValue(oldData);
          localStorage.setItem("FormOne", JSON.stringify(oldData));
          setEditData(alldata);
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
    }

    // Get Festivals
    axios({
      method: "get",
      url: mainURL + "/festivals",
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        setFestivalsList(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (editData && festivalsList.length > 0) {
      if (editData.festival !== "") {
        const festival = festivalsList.find(
          (festival) => festival.id === parseInt(editData.festival, 10)
        );
        const festivalName =
          editData && editData.festival !== ""
            ? festival
              ? festival.name
              : ""
            : "";
        setSelectedFestival(festival);
        var oldData = {
          category: editData.category,
          title: editData.title,
          website: editData.website,
          organization: editData.organization,
          festival: festivalName,
          start_date: editData.start_date,
          start_time: editData.start_time,
          end_date: editData.end_date,
          end_time: editData.end_time,
          location: editData.location,
          region: editData.region,
        };
        setInputValue(oldData);
      }
    }
    // eslint-disable-next-line
  }, [editData, festivalsList]);

  const onInputChange = (val, name) => {
    const getlocal = localStorage.getItem("FormOne");
    if (getlocal === null) {
      localStorage.setItem("FormOne", JSON.stringify(inputValue));

      var existing = localStorage.getItem("FormOne");
      existing = existing ? JSON.parse(existing) : {};

      // Logic for adding https in website by default
      if (name === "website") {
        if (val.length > 0 && val !== "https://") {
          if (val.includes("http://")) {
            val = val.split("http://");
            val = "https://" + val[1];
          } else {
            if (!val.includes("https://")) {
              val = "https://" + val;
            }
          }
        } else {
          val = "";
        }
      }

      // Add new data to localStorage Array
      existing[name] = val;

      // Save back to localStorage
      localStorage.setItem("FormOne", JSON.stringify(existing));

      setInputValue(existing);
    } else {
      var existing = localStorage.getItem("FormOne");
      existing = existing ? JSON.parse(existing) : {};

      // Logic for adding https in website by default
      if (name === "website") {
        if (val.length > 0 && val !== "https://") {
          if (val.includes("http://")) {
            val = val.split("http://");
            val = "https://" + val[1];
          } else {
            if (!val.includes("https://")) {
              val = "https://" + val;
            }
          }
        } else {
          val = "";
        }
      }

      // Add new data to localStorage Array
      existing[name] = val;
      // Save back to localStorage
      localStorage.setItem("FormOne", JSON.stringify(existing));

      setInputValue(existing);
    }
  };

  const step1 = (e, status) => {
    const error = [];

    // if (inputValue.visibility === "") {
    //   error["visibility"] = "Please select visibility";
    // }

    if (inputValue.category === "") {
      error["category"] = "Please select category";
    }

    if (inputValue.title === "") {
      error["title"] = "Opportunity Title is required";
    }

    if (inputValue.organization === "") {
      error["organization"] = "Organization is required";
    }

    if (inputValue.festival.trim() !== "" && selectedFestival === undefined) {
      error["festival"] = "Please select from the list or create festival";
    }
    if (inputValue.start_date === "") {
      error["start_date"] = "Please select Start date";
    }
    if (inputValue.start_time === "") {
      error["start_time"] = "Please select Start Time";
    }
    if (inputValue.end_date === "") {
      error["end_date"] = "Please select End Date";
    }
    if (inputValue.end_time === "") {
      error["end_time"] = "Please select End Time";
    }
    if (inputValue.location === "") {
      error["location"] = "Location is required";
    }
    // if (inputValue.region === "") {
    //   error["region"] = "Region is required";
    // }

    setError(error);
    console.log(error);
    if (Object.keys(error).length === 0) {
      let stepone = JSON.parse(localStorage.getItem("FormOne"));

      // modify for festival
      if (selectedFestival !== undefined) {
        stepone.festival = selectedFestival.id;
      }

      let method;
      let urlid;
      if (!localStorage.getItem("editopportunityid")) {
        method = "post";
        urlid = mainURL + "/opportunity";
      } else {
        method = "put";
        urlid =
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid");
      }

      const USER_TOKEN = localStorage.getItem("token");
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      axios({
        method: method,
        url: urlid,
        data: stepone,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldata = response.data.data;
          console.log("Error sds calling api", response);
          if (response.data.status === 1) {
            if (alldata.id) {
              localStorage.setItem("editopportunityid", alldata.id);
            }
            // localStorage.setItem("activestep", 2);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
      if (status === "contineu") {
        localStorage.setItem("activestep", 2);
        props.newActiveStepValue(2);
      } else {
        Swal.fire("Good job!", "Basic details have been updated", "success");
        // setSaveSucess("Basic Detail has been updated")
      }

      const getlocal = localStorage.getItem("FormOne");
      if (getlocal == null) {
        localStorage.setItem("FormOne", JSON.stringify(inputValue));
      }
    }

    console.log("error empty", Object.keys(error).length);

    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };
  const cancel = () => {
    props.history.push({
      pathname: "/user/hosting/",
    });
  };

  const handleCreateOrUpdateFestival = (festival) => {
    setShowCreateFestivalModal(false);

    // check if user is authenticated
    if (!localStorage.getItem("token")) {
      props.history.push("/login");
      return;
    }

    if (editingFestival) {
      const updatedFestival = { ...editingFestival, ...festival };

      // Update the festival in the database
      axios
        .put(`${mainURL}/festivals/${editingFestival.id}`, updatedFestival, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setFestivalsList((prevFestivals) =>
            prevFestivals.map((f) =>
              f.id === updatedFestival.id ? updatedFestival : f
            )
          );
          onInputChange(updatedFestival.name, "festival");
          setSelectedFestival(updatedFestival);
          setEditingFestival(null);
          Swal.fire("Success!", "Festival updated successfully", "success");
        })
        .catch((error) => {
          console.error("Error updating festival:", error);
          Swal.fire("Error!", "Failed to update festival", "error");
        });
    } else {
      // Create a new festival in the database
      axios
        .post(`${mainURL}/festivals`, festival, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setFestivalsList((prevFestivals) => [
            ...prevFestivals,
            response.data.data,
          ]);
          onInputChange(response.data.data.name, "festival");
          setSelectedFestival(response.data.data);
          Swal.fire("Success!", "Festival created successfully", "success");
        })
        .catch((error) => {
          console.error("Error creating festival:", error);
          Swal.fire("Error!", "Failed to create festival", "error");
        });
    }
  };

  const handleDeleteFestival = () => {
    setShowCreateFestivalModal(false);

    // check if user is authenticated
    if (!localStorage.getItem("token")) {
      props.history.push("/login");
      return;
    }

    const festivalId = editingFestival.id;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete the festival from the database
        axios
          .delete(`${mainURL}/festivals/${festivalId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then(() => {
            setFestivalsList((prevFestivals) =>
              prevFestivals.filter((f) => f.id !== festivalId)
            );
            setSelectedFestival(undefined);
            setEditingFestival(null);
            onInputChange("", "festival");
            Swal.fire("Success!", "Festival deleted successfully", "success");
          })
          .catch((error) => {
            console.error("Error deleting festival:", error);
            Swal.fire("Error!", "Failed to delete festival", "error");
          });
      }
    });
  };

  return (
    <div>
      <div className="form">
        <Form
          className="form-body"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Row className="twocolform">
            <Col className="form-group-col" md={6} controlId="formGridCategory">
              <Form.Label className="input-titlle">
                Category <span className="importantfield">*</span>
              </Form.Label>

              <Select
                bordered={false}
                className="select-category"
                dropdownMatchSelectWidth={true}
                onChange={(e) => onInputChange(e, "category")}
                options={[
                  {
                    label: "Select category",
                  },
                  {
                    value: "workshop",
                    label: "Workshop & Webinar",
                  },
                  {
                    value: "opportunity",
                    label: "Case Competition",
                  },
                ]}
                value={
                  inputValue.category ? inputValue.category : "Select category"
                }
              />
              <Form.Text className=" checkerror">{error.category}</Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="formGridOpportunityTitle"
            >
              <Form.Label className="input-titlle">
                Opportunity Title <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                defaultValue={inputValue.title}
                placeholder="Please enter opportunity name"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                required
              />
              <Form.Text className=" checkerror">{error.title}</Form.Text>
            </Col>
          </Form.Row>

          <Form.Row className="twocolform">
            <Col className="form-group-col" md={6} controlId="formGridWebsite">
              <Form.Label className="input-titlle">Website</Form.Label>
              <Form.Control
                type="text"
                name="website"
                placeholder="Please enter company website"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                value={inputValue.website}
                required
              />
              <Form.Text className=" checkerror">{error.website}</Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="formGridOrganization"
            >
              <Form.Label className="input-titlle">
                Organization <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="organization"
                placeholder="Please enter organization name"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={
                  inputValue.organization && inputValue.organization
                }
                required
              />
              <Form.Text className=" checkerror">
                {error.organization}
              </Form.Text>
            </Col>
          </Form.Row>

          {/* === Festival Row Start === */}
          <Form.Row>
            <Col
              className="form-group-col "
              md={9}
              controlId="formGridFestivals"
            >
              <Form.Label className="input-titlle">Festivals </Form.Label>
              <Form.Control
                placeholder="Start typing to search festival"
                name="festival"
                onChange={(e) => {
                  e.target.value.trim().length > 2 &&
                    setSuggestedFestivalList(
                      [...festivalsList].filter((festival) =>
                        festival.name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    );
                  setSelectedFestival(undefined);
                  onInputChange(e.target.value, e.target.name);
                }}
                onFocus={() => {
                  setError({
                    ...error,
                    festival: "",
                  });

                  inputValue.festival.trim().length > 2 &&
                    setSuggestedFestivalList(
                      [...festivalsList].filter((festival) =>
                        festival.name
                          .toLowerCase()
                          .includes(inputValue.festival.toLowerCase())
                      )
                    );
                }}
                onBlur={() => {
                  setTimeout(() => setSuggestedFestivalList([]), 500);
                }}
                defaultValue={inputValue.festival}
                value={inputValue.festival}
                required
              />
              {/* Festival Suggestion */}
              {suggestedFestivalList.length > 0 && (
                <>
                  <ul className="festival-suggestion-box">
                    {suggestedFestivalList.map((suggestion, i) => (
                      <li
                        key={i}
                        className="festival-suggestion-item"
                        onClick={() => {
                          onInputChange(suggestion.name, "festival");
                          setSelectedFestival(suggestion);
                          setSuggestedFestivalList([]);
                        }}
                      >
                        {suggestion.name}
                        <FaEdit
                          onClick={(e) => {
                            e.preventDefault();
                            setEditingFestival(suggestion);
                            setShowCreateFestivalModal(true);
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <Form.Text className=" checkerror">{error.festival}</Form.Text>
            </Col>

            <Col className="form-group-col " md={3}>
              <div className="create-new-festival-btn-conainer">
                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCreateFestivalModal(true);
                  }}
                >
                  <FiPlusCircle /> Create New Festival
                </button>
              </div>
            </Col>
          </Form.Row>
          {/* === Festival Row End === */}

          <Form.Row className="twocolform">
            <Col
              controlId="oppotunityEndDate"
              className="form-group-col"
              md={6}
            >
              <Form.Label className="input-titlle">
                Opportunity Start Date <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                min={today}
                max={inputValue.end_date && inputValue.end_date}
                placeholder="21-04-2021d"
                name="start_date"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                value={inputValue?.start_date}
                required
              />
              <Form.Text className=" checkerror">{error.start_date}</Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="oppotunityEndTime"
            >
              <Form.Label className="input-titlle">
                Opportunity Start Time <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="00:00:00"
                name="start_time"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={inputValue?.start_time}
                required
              />
              <Form.Text className=" checkerror">{error.start_time}</Form.Text>
            </Col>
          </Form.Row>

          <Form.Row className="twocolform">
            <Col
              controlId="oppotunityEndDate"
              className="form-group-col"
              md={6}
            >
              <Form.Label className="input-titlle">
                Opportunity End Date <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                min={inputValue.start_date}
                placeholder="21-04-2021"
                name="end_date"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                value={inputValue?.end_date}
                required
              />
              <Form.Text className=" checkerror">{error.end_date}</Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="oppotunityEndTime"
            >
              <Form.Label className="input-titlle">
                Opportunity End Time <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="00:00:00"
                name="end_time"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={inputValue.end_time}
                required
              />
              <Form.Text className=" checkerror">{error.end_time}</Form.Text>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col className="form-group-col" md={12} controlId="location">
              <Form.Label className="input-titlle">
                Location <span className="importantfield">*</span>
              </Form.Label>
              <div
                className="form-control radiocss"
                name="location"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
              >
                <Form.Check
                  type="radio"
                  value="online"
                  label="Online"
                  name="location"
                  checked={
                    inputValue.location === null
                      ? null
                      : inputValue.location === "online"
                  }
                />
                <Form.Check
                  type="radio"
                  value="offline"
                  label="Offline"
                  name="location"
                  checked={
                    inputValue.location === null
                      ? null
                      : inputValue.location === "offline"
                  }
                />
              </div>

              <Form.Text className=" checkerror">{error.location}</Form.Text>
            </Col>
          </Form.Row>

          {/* <Form.Row>
          <Col className="form-group-col" md={12} controlId="oppotunityEndTime">
            <Form.Label className="input-titlle">Region <span className="importantfield">*</span></Form.Label>

            <div
              className="form-control radiocss"
              onChange={(e) => onInputChange(e.target.value, e.target.name)}
            >
              <Form.Check
                type="radio"
                value="india"
                name="region"
                label="India"
                checked={inputValue.region === "india"}
              />
              <Form.Check
                type="radio"
                value="global"
                name="region"
                label="Global"
                checked={inputValue.region === "global"}
              />
            </div>
            <Form.Text className=" checkerror">{error.location}</Form.Text>
          </Col>
        </Form.Row> */}
          {saveSucess && <p className="success-save">{saveSucess}</p>}
        </Form>
      </div>
      <div className="hosting_bottom">
        <Button
          className="save-hosting-first-btn"
          type="button"
          onClick={(e) => step1(e, "contineu")}
        >
          Next <FaArrowRight />
        </Button>
        {localStorage.getItem("opportunityfrom") && (
          <Button
            className="save-hosting-first-btn editsavebtn"
            type="button"
            onClick={(e) => step1(e, "save")}
          >
            save <FaSave />
          </Button>
        )}
        <Link to={`/user/hosting`}>
          {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
            // onClick={(e) => cancel(e)}
          >
            Cancel
          </Button>
        </Link>
      </div>

      {showCreateFestivalModal && (
        <CreateFestivalModal
          editingFestival={editingFestival}
          onClose={() => {
            setEditingFestival(null);
            setShowCreateFestivalModal(false);
          }}
          onCreateFestival={handleCreateOrUpdateFestival}
          onDeleteFestival={handleDeleteFestival}
        />
      )}
    </div>
  );
};

export default StepOne;
