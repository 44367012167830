import { Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import CKEditor from "react-ckeditor-component";
import { IoCloseOutline } from "react-icons/io5";
import {
  getSignedUrls,
  uploadFileWithProgress,
} from "../../../services/uploadService.js";
import "./CreateFestivalModal.css";
import FestivalDetailsEditor, {
  CKEditorConfig,
} from "./FestivalDetailsEditor.js";

function CreateFestivalModal({
  editingFestival,
  onClose,
  onCreateFestival,
  onDeleteFestival,
}) {
  const [festivalDetails, setFestivalDetails] = useState(undefined);
  const [aboutOrganization, setAboutOrganization] = useState(undefined);
  const [eligibility, setEligibility] = useState("");
  const [errors, setErrors] = useState({});
  const [uploading, setUploading] = useState({
    logo: false,
    banners: false,
    gallery: false,
  });
  const [inputValue, setInputValue] = useState({
    name: "",
    organization: "",
    website: "",
    eligibility: "",
    details: "",
    aboutOrganization: "",
    logo: "",
    banners: [],
    gallery: [],
  });

  useEffect(() => {
    if (editingFestival) {
      const data = {
        ...editingFestival,
        banners: editingFestival.banners ? JSON.parse(editingFestival.banners) : [],
        gallery: editingFestival.gallery ? JSON.parse(editingFestival.gallery) : [],
      };
      setInputValue(data);
      setFestivalDetails(data.details);
      setAboutOrganization(data.aboutOrganization);
    }
    // eslint-disable-next-line
  }, [editingFestival]);

  useEffect(() => {
    if (festivalDetails && inputValue.details !== festivalDetails) {
      onInputChange(festivalDetails, "details");
    }

    if (
      aboutOrganization &&
      inputValue.aboutOrganization !== aboutOrganization
    ) {
      onInputChange(aboutOrganization, "aboutOrganization");
    }
    // eslint-disable-next-line
  }, [aboutOrganization, festivalDetails]);

  const onInputChange = (val, name) => {
    if (name !== "logo" && name !== "banners" && name !== "gallery") {
      setErrors({ ...errors, [name]: "" });

      // Logic for adding https in website by default
      if (name === "website") {
        val = val.trim();
        if (val.length > 0 && val !== "https://") {
          if (val.includes("http://")) {
            val = val.split("http://");
            val = "https://" + val[1];
          } else {
            if (!val.includes("https://")) {
              val = "https://" + val;
            }
          }
        } else {
          val = "";
        }
      }

      setInputValue((prevState) => ({
        ...prevState,
        [name]: val,
      }));
    } else {
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

      if (validImageTypes.includes(val.type) && val.size <= maxSizeInBytes) {
        setErrors({ ...errors, [name]: "" });
        uploadFile(val, name);
      } else {
        setErrors({
          ...errors,
          [name]: "Please select a valid image file of less than 5MB in size",
        });
      }
    }
  };

  const onRemoveEligibilityItem = (index) => {
    let eligibilityList = inputValue.eligibility.split(", ");
    eligibilityList.splice(index, 1);
    eligibilityList = eligibilityList.join(", ");
    onInputChange(eligibilityList, "eligibility");
  };

  const uploadFile = async (file, fieldName) => {
    setUploading({ ...uploading, [fieldName]: true });

    // Generate a new file name with a timestamp
    const newFileName = `${moment().valueOf()}-${file.name}`;
    const newFile = new File([file], newFileName, { type: file.type });

    try {
      // Get the signed URL for the new file name
      const signedUrl = await getSignedUrls([{ fileName: newFile.name }]);

      // Upload the file using the signed URL
      await uploadFileWithProgress(signedUrl[0].signedUploadURL, newFile);

      const fileUrl = signedUrl.find(
        (s) => s.fileName === newFile.name
      ).fileURL;

      if (fieldName === "banners" || fieldName === "gallery") {
        setInputValue({
          ...inputValue,
          [fieldName]: [...inputValue[fieldName], fileUrl],
        });
      } else {
        setInputValue({ ...inputValue, [fieldName]: fileUrl });
      }

      setUploading({ ...uploading, [fieldName]: false });
    } catch (error) {
      console.error("File upload failed:", error);
      setErrors({
        ...errors,
        [fieldName]: "An error occurred while uploading the file",
      });
    }
  };

  const handleSubmitClick = () => {
    // return if already errors exist
    if (Object.values(errors).some((error) => error !== "")) {
      return;
    }

    // Validate form fields
    const formErrors = validateForm(inputValue);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Process data and pass to parent component
    const processedData = {
      ...inputValue,
      name: inputValue.name.trim(),
      organization: inputValue.organization.trim(),
      website: inputValue.website.trim(),
      details: inputValue.details.trim(),
      aboutOrganization: inputValue.aboutOrganization.trim(),
      banners: JSON.stringify(inputValue.banners),
      gallery: JSON.stringify(inputValue.gallery),
    };

    onCreateFestival(processedData);
  };

  const validateForm = (formData) => {
    const formErrors = {};

    // Check required fields
    if (!formData.name.trim()) {
      formErrors.name = "Festival name is required.";
    }
    if (!formData.organization.trim()) {
      formErrors.organization = "Organization is required.";
    }
    if (!formData.website.trim()) {
      formErrors.website = "Website is required.";
    }
    if (!formData.eligibility.trim()) {
      formErrors.eligibility = "Eligibility is required.";
    }
    if (!formData.details.trim()) {
      formErrors.details = "Details are required.";
    }
    if (!formData.aboutOrganization.trim()) {
      formErrors.aboutOrganization = "About Organization is required.";
    }
    if (!formData.logo) {
      formErrors.logo = "Festival Logo is required.";
    }
    if (!formData.banners.length) {
      formErrors.banners = "At least one Festival Banner is required.";
    }

    return formErrors;
  };

  return (
    <div className="festival-modal-overlay">
      <div
        className="festival-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="festival-close-modal-btn"
          onClick={onClose}
          disabled={Object.values(uploading).some((item) => item === true)}
        >
          &times;
        </button>
        <h3>{editingFestival ? "Edit Festival" : "Create Festival"}</h3>

        <div className="festival-modal-scrollable-content">
          <div className="festival-form-row">
            <div className="festival-form-group">
              <label htmlFor="festival name">
                Festival Name <span className="importantfield">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Festival Name"
                value={inputValue.name}
                onChange={(e) => onInputChange(e.target.value, "name")}
              />
              <span className="importantfield">{errors.name}</span>
            </div>
          </div>

          <div className="festival-form-row">
            <div className="festival-form-group">
              <label htmlFor="organization">
                Organization <span className="importantfield">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Organization"
                value={inputValue.organization}
                onChange={(e) => onInputChange(e.target.value, "organization")}
              />
              <span className="importantfield">{errors.organization}</span>
            </div>
            <div className="festival-form-group">
              <label htmlFor="website">
                Website <span className="importantfield">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Website"
                value={inputValue.website}
                onChange={(e) => onInputChange(e.target.value, "website")}
              />
              <span className="importantfield">{errors.website}</span>
            </div>
          </div>

          <div className="festival-form-row">
            <div className="festival-form-group eligibility">
              <label htmlFor="eligibility">
                Eligibility <span className="importantfield">*</span>
              </label>

              {inputValue.eligibility && inputValue.eligibility.length > 0 && (
                <div className="opportunity-lists">
                  {inputValue.eligibility.split(",").map((item, index) => {
                    return (
                      <div className="opportunity-item" key={index}>
                        {item}
                        <IoCloseOutline
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => onRemoveEligibilityItem(index)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              <Input
                type="text"
                placeholder="Type your input then press enter to add"
                onChange={(e) => setEligibility(e.target.value)}
                onPressEnter={(e) => {
                  if (e.target.value.trim().length > 0) {
                    onInputChange(
                      `${inputValue.eligibility ? inputValue.eligibility + ", " : ""}${e.target.value.trim()}`,
                      "eligibility"
                    );
                    setEligibility("");
                  }
                }}
                value={eligibility}
              />
              <span className="importantfield">{errors.eligibility}</span>
            </div>
          </div>

          <div className="festival-form-row">
            <div className="festival-form-group">
              <label htmlFor="details">
                Festival Details <span className="importantfield">*</span>
              </label>
              <FestivalDetailsEditor
                festivalDetails={festivalDetails}
                OnFestivalDetailsChange={(content) =>
                  setFestivalDetails(content)
                }
              />
              <span className="importantfield">{errors.details}</span>
            </div>
          </div>

          <div className="festival-form-row">
            <div className="festival-form-group">
              <label htmlFor="aboutOrganization">
                About Organization <span className="importantfield">*</span>
              </label>
              <CKEditor
                id="cke_editor2"
                content={aboutOrganization}
                config={CKEditorConfig}
                events={{
                  change: (e) => {
                    const data = e.editor.getData();
                    setAboutOrganization(data);
                  },
                }}
              />
              <span className="importantfield">{errors.aboutOrganization}</span>
            </div>
          </div>

          {/* Logo Upload Section */}
          <div className="festival-form-row">
            <div className="festival-form-group">
              <span className="uploader logosec">
                <input
                  type="file"
                  id="file-upload"
                  accept="image/*"
                  name="logo"
                  value=""
                  disabled={uploading.logo}
                  onChange={(e) => onInputChange(e.target.files[0], "logo")}
                />

                <label htmlFor="file-upload" id="file-drag">
                  <div id="start">
                    {inputValue.logo && (
                      <div>
                        <div className="festival-image-container">
                          <img
                            id="file-image"
                            src={inputValue.logo}
                            alt="Preview"
                            className="festival-upload-preview"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(inputValue.logo, "_blank");
                            }}
                            title="Click to view image in new tab"
                          />
                          <button
                            title="Remove Image"
                            onClick={(e) => {
                              e.preventDefault();
                              setInputValue({
                                ...inputValue,
                                logo: "",
                              });
                            }}
                            className="festival-image-remove-button"
                          >
                            &times;
                          </button>
                        </div>
                      </div>
                    )}
                    {!uploading.logo ? (
                      <>
                        <i className="fa fa-upload" aria-hidden="true"></i>
                        <div>
                          {inputValue.logo
                            ? `Replace festival logo `
                            : "Upload festival logo"}{" "}
                          <span className="importantfield">*</span>
                        </div>
                      </>
                    ) : (
                      <div className="image-uploading-container">
                        <Spinner
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          style={{ width: "1rem", height: "1rem" }}
                        />{" "}
                        Uploading...
                      </div>
                    )}
                  </div>
                </label>
              </span>
              <span className="importantfield">{errors.logo}</span>
            </div>
          </div>
          {/* Logo Upload Section End */}

          {/* Banners Upload Section */}
          <div className="festival-form-row">
            <div className="festival-form-group">
              <span className="uploader logosec">
                <input
                  type="file"
                  id="file-upload-banner"
                  accept="image/*"
                  name="banners"
                  value=""
                  disabled={uploading.banners}
                  onChange={(e) => onInputChange(e.target.files[0], "banners")}
                />

                <label htmlFor="file-upload-banner" id="file-drag-banner">
                  <div id="start">
                    {inputValue.banners.length > 0 && (
                      <div className="festival-multiple-image-preview-container">
                        {inputValue.banners.map((item, index) => {
                          return (
                            <div
                              className="festival-image-container"
                              key={index}
                            >
                              <img
                                id="file-image"
                                src={item}
                                alt="Preview"
                                className="festival-upload-preview"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(item, "_blank");
                                }}
                                title="Click to view image in new tab"
                              />
                              <button
                                title="Remove Image"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setInputValue({
                                    ...inputValue,
                                    banners: inputValue.banners.filter(
                                      (item, i) => i !== index
                                    ),
                                  });
                                }}
                                className="festival-image-remove-button"
                              >
                                &times;
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {!uploading.banners ? (
                      <>
                        <i className="fa fa-upload" aria-hidden="true"></i>
                        <div>
                          {inputValue.banners.length > 0
                            ? `Upload another festival banner `
                            : "Upload festival banner"}{" "}
                          {inputValue.banners.length === 0 && (
                            <span className="importantfield">*</span>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="image-uploading-container">
                        <Spinner
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          style={{ width: "1rem", height: "1rem" }}
                        />{" "}
                        Uploading...
                      </div>
                    )}
                  </div>
                </label>
              </span>
              <span className="importantfield">{errors.banners}</span>
            </div>
          </div>
          {/* Banners Upload Section End */}

          {/* Gallery Upload Section */}
          <div className="festival-form-row">
            <div className="festival-form-group">
              <span className="uploader logosec">
                <input
                  type="file"
                  id="file-upload-gallery"
                  accept="image/*"
                  name="gallery"
                  value=""
                  disabled={uploading.gallery}
                  onChange={(e) => onInputChange(e.target.files[0], "gallery")}
                />

                <label htmlFor="file-upload-gallery" id="file-drag-gallery">
                  <div id="start">
                    {inputValue.gallery.length > 0 && (
                      <div className="festival-multiple-image-preview-container">
                        {inputValue.gallery.map((item, index) => {
                          return (
                            <div
                              className="festival-image-container"
                              key={index}
                            >
                              <img
                                id="file-image"
                                src={item}
                                alt="Preview"
                                className="festival-upload-preview"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(item, "_blank");
                                }}
                                title="Click to view image in new tab"
                              />
                              <button
                                title="Remove Image"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setInputValue({
                                    ...inputValue,
                                    gallery: inputValue.gallery.filter(
                                      (item, i) => i !== index
                                    ),
                                  });
                                }}
                                className="festival-image-remove-button"
                              >
                                &times;
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {!uploading.gallery ? (
                      <>
                        <i className="fa fa-upload" aria-hidden="true"></i>
                        <div>Upload images to showcase in gallery</div>
                      </>
                    ) : (
                      <div className="image-uploading-container">
                        <Spinner
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          style={{ width: "1rem", height: "1rem" }}
                        />{" "}
                        Uploading...
                      </div>
                    )}
                  </div>
                </label>
              </span>
              <span className="importantfield">{errors.gallery}</span>
            </div>
          </div>
          {/* Gallery Upload Section End */}
        </div>

        <div className="festival-form-btn-section">
          {editingFestival && (
            <button
              onClick={onDeleteFestival}
              className="btn btn-danger float-left"
              disabled={Object.values(uploading).some((item) => item === true)}
            >
              Delete Festival
            </button>
          )}

          <button
            onClick={handleSubmitClick}
            className="festival-submit-btn btn btn-danger float-right"
            disabled={Object.values(uploading).some((item) => item === true)}
          >
            Submit
          </button>
          <button
            className="btn btn-outline-secondary float-right"
            onClick={onClose}
            disabled={Object.values(uploading).some((item) => item === true)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateFestivalModal;
