import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import config from "../../CommonFiles/config.json";
import Footer from "../../CommonFiles/Footer";
import Header from "../../CommonFiles/Header";
import OpportunitiesRightSideCard from "../../CommonFiles/OpportunitiesRightSideCard";
import "./FestivalDetails.css";
import FestivalHeader from "./FestivalHeader";
import Helmet from "react-helmet";
import ImageGallery from "../../CommonFiles/UI Elements/ImageGallery/ImageGallery";

const API_URL = Object.values(config["URL"]).join("");

const FestivalDetails = (props) => {
  const sections = [
    { id: "opportunities", label: "Opportunities" },
    { id: "details", label: "Festival Details" },
    { id: "aboutOrganization", label: "About Organization" },
    { id: "gallery", label: "Gallery" },
  ];
  const [loginstatus, setLoginstatus] = useState(false);
  const [festivalDetails, setFestivalDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const sectionRefs = useRef(sections.map(() => React.createRef()));
  const [activeSection, setActiveSection] = useState(sections[0].id);

  useEffect(() => {
    if (!props.match.params.id) {
      window.location.href = "/";
    } else {
      // Get the festival details
      const festivalId = props.match.params.id;
      setLoading(true);
      axios
        .get(`${API_URL}/festivals/${festivalId}`)
        .then((response) => {
          let festivalDetailsObject = response.data.data;
          festivalDetailsObject = {
            ...festivalDetailsObject,
            banners: JSON.parse(festivalDetailsObject.banners),
            gallery:
              festivalDetailsObject.gallery.length > 0
                ? JSON.parse(festivalDetailsObject.gallery)
                : [],
          };

          setFestivalDetails(festivalDetailsObject);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          window.location.href = "/";
        });
    }
    // eslint-disable-next-line
  }, []);

  // Auto Scroll to the section related codes starts here
  const handleScroll = () => {
    const sectionPositions = sectionRefs.current.map((ref, index) => {
      const rect = ref.current?.getBoundingClientRect();
      return {
        id: sections[index].id,
        top: rect?.top ?? 0,
        bottom: rect?.bottom ?? 0,
      };
    });

    const stickyOffset = 200; // Height of the sticky elements at the top

    // Determine the section closest to the adjusted viewport top
    const active = sectionPositions.reduce((closest, current) => {
      const viewportMiddle =
        stickyOffset + (window.innerHeight - stickyOffset) / 2;

      if (current.top <= viewportMiddle && current.bottom >= viewportMiddle) {
        return current;
      }
      return closest;
    }, null);

    if (active && active.id !== activeSection) {
      setActiveSection(active.id);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [activeSection]);

  const scrollToSection = (ref) => {
    const stickyOffset = 300; // Height of the sticky header

    if (ref.current) {
      const elementPosition =
        ref.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - stickyOffset;

      // Scroll to the adjusted position
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      setActiveSection(ref.current.id); // Manually set the active section after scrolling
    }
  };
  // Auto Scroll to the section related codes ends here

  const registernowclic = () => {
    const USER_TOKEN = localStorage.getItem("token");
    if (!USER_TOKEN) {
      setLoginstatus(false);
      if (localStorage.getItem("loginpopup") === "false") {
        setLoginstatus(true);
        localStorage.setItem("loginpopup", "true");
      }
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content={festivalDetails && festivalDetails.details}
        />
        <meta
          property="og:title"
          content={festivalDetails && festivalDetails.name + "| Grad Partners"}
        />
        <meta property="og:url" content="https://www.gradpartners.in/" />
      </Helmet>

      <Header openlogin={loginstatus} registernowclic={registernowclic} />
      {!loading && festivalDetails && (
        <div className="festival-main-container">
          {/* Header Section */}
          <FestivalHeader festivalDetails={festivalDetails} />

          <div className="festival-main-content">
            {/* Slider Section */}

            <Swiper
              className="container"
              modules={[Navigation, Pagination, A11y, Autoplay]}
              spaceBetween={20}
              slidesPerView={1}
              navigation={{
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
              }}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              speed={2000}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
              }}
            >
              <div className="swiper-wrapper">
                {festivalDetails.banners.map((banner, index) => (
                  <SwiperSlide key={index}>
                    <div className="slider-item">
                      <img
                        src={banner}
                        alt="Festival Banner"
                        className="slider-image"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </div>
              <div
                className={`swiper-buttons text-center detailslider mt-4 mb-4 ${
                  festivalDetails.banners.length <= 1 && "arrowhidden"
                }`}
              >
                <div className="swiper-btn-prev">
                  <FaArrowLeft />
                </div>
                <div className="swiper-pagination dd"></div>
                <div className="swiper-btn-next">
                  <FaArrowRight />
                </div>
              </div>
            </Swiper>

            {/* Tabs */}
            <div className="tabs sticky-tabs">
              {sections.map((section, index) => {
                if (
                  (section.id === "opportunities" &&
                    festivalDetails.opportunities.length === 0) ||
                  (section.id === "gallery" &&
                    festivalDetails.gallery.length === 0)
                )
                  return null;

                return (
                  <button
                    key={section.id}
                    onClick={() => scrollToSection(sectionRefs.current[index])}
                    className={activeSection === section.id ? "active" : ""}
                  >
                    {section.label}
                  </button>
                );
              })}
            </div>

            {/* Content Sections */}
            <div className="container content-section">
              {festivalDetails.opportunities.length > 0 && (
                <>
                  {/* Opportunities Section */}
                  <h2 className="section-title">Opportunities</h2>
                  <div
                    key={sections[0].id}
                    id={sections[0].id}
                    ref={sectionRefs.current[0]}
                    className="opportunity-section"
                  >
                    {festivalDetails.opportunities.map((item, index) => (
                      <div className="opportunity-cards" key={index}>
                        <OpportunitiesRightSideCard item={item} />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {/* Festival Details Section */}
              <h2 className="section-title">Festival Details</h2>
              <div
                key={sections[1].id}
                id={sections[1].id}
                ref={sectionRefs.current[1]}
                className="section"
              >
                <div
                  className="details"
                  dangerouslySetInnerHTML={{ __html: festivalDetails.details }}
                />
              </div>

              {/* About Organization Section */}
              <h2 className="section-title">
                About {festivalDetails.organization}
              </h2>
              <div
                key={sections[2].id}
                id={sections[2].id}
                ref={sectionRefs.current[2]}
                className="section"
                style={{ backgroundColor: "#fcf6f0" }}
              >
                <div
                  className="details"
                  dangerouslySetInnerHTML={{
                    __html: festivalDetails.aboutOrganization,
                  }}
                />
              </div>

              {/* Gallery Section */}
              {festivalDetails.gallery.length > 0 && (
                <>
                  <h2 className="section-title">Gallery</h2>
                  <div
                    key={sections[3].id}
                    id={sections[3].id}
                    ref={sectionRefs.current[3]}
                    className="gallery-section"
                  >
                    <ImageGallery images={festivalDetails.gallery} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="loader-container">
          <Spinner
            animation="grow"
            variant="danger"
            style={{ height: "100px", width: "100px" }}
          />
        </div>
      )}
      <span className="mainhomepage">
        <Footer />
      </span>
    </React.Fragment>
  );
};

export default FestivalDetails;
