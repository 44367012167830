import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./OpportunitiesRightSideCard.css";

function OpportunitiesRightSideCard(props) {
  const [timeLeft, setTimeLeft] = useState("Loading...");

  useEffect(() => {
    const updateTimer = () => {
      const endDateTime = moment(props.item.register_end_date);
      const endTimeMoment = moment(props.item.register_end_time, "HH:mm");

      endDateTime.set({
        hour: endTimeMoment.hour(),
        minute: endTimeMoment.minute(),
        second: endTimeMoment.second(),
      });

      const now = moment();
      const timeDifference = endDateTime.diff(now);
      const duration = moment.duration(timeDifference);

      if (timeDifference <= 0) {
        setTimeLeft("Closed");
        return;
      }

      if (duration.asDays() >= 1) {
        const daysLeft = Math.floor(duration.asDays());
        setTimeLeft(`${daysLeft} day${daysLeft > 1 ? "s" : ""} left`);
        return;
      }

      const hours = duration.hours().toString().padStart(2, "0");
      const minutes = duration.minutes().toString().padStart(2, "0");
      const seconds = duration.seconds().toString().padStart(2, "0");
      setTimeLeft(`${hours}:${minutes}:${seconds} Left`);
    };

    // Initial update and setting interval
    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [props.item.register_end_date, props.item.register_end_time]);

  return (
    <div className="opportunities-rightside-cards">
      <a
        href={`${window.location.origin}/e/${props.item.slug}`}
        target="_blank"
        rel="noreferrer"
      >
        <Card
          className={
            props.status === 1
              ? "workshop-card-dash"
              : "workshop-card detailevent"
          }
        >
          {props.item.banner_image ? (
            <Card.Img
              variant="top"
              className="listingimg"
              src={props.item.banner_image}
            />
          ) : (
            <div className="no-banner">Banner Not Found</div>
          )}
          <div className="opportunity-box">
            <div className="workshop-date">
              {moment(props.item.start_date).format("Do MMM'YY")} -{" "}
              {moment(props.item.end_date).format("Do MMM'YY")}
            </div>
            <div className="workshop-title">
              <p>{props.item.title}</p>
              <span>Hosted By: {props.item.organization}</span>
              <br />
              <span>Impressions : {props.item.views}</span>
              <br />
              {!props.item.external_registration && (
                <span>Registrations : {props.item.register_count}</span>
              )}
            </div>

            <div className="cardBottom">
              <a
                href={`${window.location.origin}/e/${props.item.slug}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="primary" className="opportunities-card-btn">
                  Explore <i className="fa fa-arrow-right"></i>
                </Button>
              </a>
              <span className="days-left">{timeLeft}</span>
            </div>
          </div>
        </Card>
      </a>
    </div>
  );
}

export default OpportunitiesRightSideCard;
