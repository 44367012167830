import { Popconfirm, Tabs } from "antd";
import axios from "axios";
import $ from "jquery";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import config from "../CommonFiles/config.json";
import CreateFestivalModal from "../DynamicPages/FormSteps/CreateFestivalModal";
import FestivalList from "./FestivalList/FestivalList";

const mainURL = Object.values(config["URL"]).join("");

const Userhosting = (props) => {
  const { TabPane } = Tabs;
  const [alldata, setAlldata] = useState([]);
  const [tempData, setTempData] = useState();
  const [sortData, setSortByData] = useState([]);
  const [filterdata, setFilterData] = useState("all");
  const [sortbyvalue, setSortByValue] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [registrationDeadline, setRegistrationDeadline] = useState();
  const [teamdetail, setTeamdetail] = useState();
  const [selectedtab, setSelctedtab] = useState(1);
  const [allFestivals, setAllFestivals] = useState([]);
  const [showCreateFestivalModal, setShowCreateFestivalModal] = useState(false);
  const [editingFestival, setEditingFestival] = useState(null);

  const USER_TOKEN = localStorage.getItem("token");
  const AuthStr = "Bearer ".concat(USER_TOKEN);
  const data = [
    {
      value: 0,
      text: "All",
      icon: <i className="fa fa-bars"></i>,
    },
    {
      value: 1,
      text: "Live",
      icon: <i className="fa fa-circle"></i>,
    },
    {
      value: 2,
      text: "Closed",
      icon: <i className="fa fa-lock"></i>,
    },
  ];

  const sortby = [
    {
      value: 0,
      text: "Alphabetically",
      icon: <i className="fa fa-bars"></i>,
    },
    {
      value: 1,
      text: "Opportunity Deadline",
      icon: <i className="fa fa-circle"></i>,
    },
  ];
  useEffect(() => {
    localStorage.removeItem("editopportunityid");
    localStorage.removeItem("activestep");

    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      getOpportunity();
      getFestivals();
    }
    // eslint-disable-next-line
  }, []);

  const getOpportunity = () => {
    axios({
      method: "get",
      url: mainURL + "/profile/opportunities",
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldatas = response.data.data.rows;
        setTeamdetail(response.data.teamlead);
        setAlldata(alldatas);
        setTempData(alldatas);
      })
      .catch(function (error) {
        //handle error
        if (error?.response?.data?.auth === false) {
          Swal.fire({
            title: "Your session has expired, please login again!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            allowOutsideClick: false,
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.clear();
              window.location.href = "/";
            }
          });
        }
        console.log("Error while calling api", error.response);
      });
  };

  const getFestivals = () => {
    axios({
      method: "get",
      url: mainURL + "/festivals?includeOpportunities=true",
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // Sort the array by createdAt in descending order (newest first)
        response.data.data.length > 1 &&
          response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
        setAllFestivals(response.data.data);
      })
      .catch(function (error) {
        //handle error
        if (error?.response?.data?.auth === false) {
          Swal.fire({
            title: "Your session has expired, please login again!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            allowOutsideClick: false,
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.clear();
              window.location.href = "/";
            }
          });
        }
        console.log("Error while calling api", error.response);
      });
  };

  function cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }
  function selectetab(status) {
    setSelctedtab(status);
    setSearchValue("");
    if (registrationDeadline === 0) {
      setAlldata(tempData);
    }
  }

  const finalfilterdata = (e, status) => {
    let statusoflisting;
    let sortof;
    let searchExist;
    if (status === "searchData") {
      setSearchValue(e);
      statusoflisting =
        typeof registrationDeadline != "undefined"
          ? "&status=" + registrationDeadline
          : "";
      sortof =
        typeof sortbyvalue !== "undefined" ? "&sortby=" + sortbyvalue : "";
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/opportunities?search=" +
          e +
          statusoflisting +
          sortof,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data.rows;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }

    if (status === "statusoflisting") {
      searchExist = searchValue !== "" ? "&search=" + searchValue : "";
      sortof =
        typeof sortbyvalue !== "undefined" ? "&sortby=" + sortbyvalue : "";
      setRegistrationDeadline(e);
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/opportunities?" +
          searchExist +
          "&status=" +
          e +
          sortof,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data.rows;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
    if (status === "sortby") {
      searchExist = searchValue !== "" ? "&search=" + searchValue : "";
      statusoflisting =
        typeof registrationDeadline != "undefined"
          ? "&status=" + registrationDeadline
          : "";

      setSortByValue(e);
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/opportunities?" +
          searchExist +
          statusoflisting +
          "&sortby=" +
          e,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data.rows;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  };
  const searchRegistrations = (e) => {
    setSearchValue(e);
    if (registrationDeadline !== 0) {
      handleRegistrationDeadline(registrationDeadline);
    }
    if (sortbyvalue !== 0) {
      sortbyChange(sortbyvalue);
    }
    // setsor

    const searchList = alldata.filter((item) => {
      return item.title.toLowerCase().indexOf(e.toLowerCase()) !== -1;
    });

    setAlldata(searchList);
  };

  const handleRegistrationDeadline = (e) => {
    setRegistrationDeadline(e);
    const filterdata = [];

    if (searchValue !== "") {
      searchRegistrations(searchValue);
    }
    if (sortbyvalue !== 0) {
      sortbyChange(sortbyvalue);
    }
    for (let index = 0; index < alldata.length; index++) {
      const element = alldata[index];

      if (e === 2) {
        if (
          moment(element.register_end_date).diff(moment(new Date()), "days") <=
          0
        ) {
          filterdata.push(element);
        }
      } else if (e === 1) {
        if (
          moment(element.register_end_date).diff(moment(new Date()), "days") > 0
        ) {
          filterdata.push(element);
        }
      }
    }

    setAlldata(filterdata);
  };

  const gotoEdithosting = (hosting_id) => {
    props.history.push({
      pathname: "/manage/opportunity",
      state: hosting_id,
    });
    localStorage.setItem("editopportunityid", hosting_id);
    localStorage.setItem("opportunityfrom", "editopp");
  };

  const gotohosting = () => {
    localStorage.removeItem("FormFive");
    localStorage.removeItem("FormSix");
    localStorage.removeItem("FormFour");
    localStorage.removeItem("FormOne");
    localStorage.removeItem("FormTwo");
    localStorage.removeItem("FormThree");
    localStorage.removeItem("FormSeven");
    localStorage.removeItem("opportunityfrom");
    localStorage.removeItem("editopportunityid");
    localStorage.removeItem("activestep");
    props.history.push({
      pathname: "/manage/opportunity",
    });
  };
  const gotodashboard = (hosting_id) => {
    localStorage.setItem("hostingId", hosting_id);

    props.history.push({
      pathname: "/user/opportunity/dashboard/" + hosting_id,
      state: hosting_id,
    });
  };

  const sortbyChange = (e) => {
    setSortByValue(e.value);
    if (e.value === 0) {
      setFilterData("title");
      setSortByData(
        alldata.sort(function (a, b) {
          if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
          if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
          //  return 0;
        })
      );
    }
    if (e.value === 1) {
      setFilterData("deadline");
      setSortByData(
        alldata.sort((a, b) =>
          a.end_date
            .split("/")
            .reverse()
            .join()
            .localeCompare(b.end_date.split("/").reverse().join())
        )
      );
    }
  };

  const duplicate = (id) => {
    axios({
      method: "post",
      url: mainURL + "/opportunity/duplicate",
      data: {
        opportunity_id: id,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        getOpportunity();
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  // delete opportunity

  const deleteOpp = (id) => {
    axios({
      method: "delete",
      url: mainURL + "/opportunity/" + id,
      data: {
        opportunity_id: id,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        let docid = "#opportunity" + id;
        $(docid).hide();
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const handleCreateOrUpdateFestival = (festival) => {
    setShowCreateFestivalModal(false);

    // check if user is authenticated
    if (!localStorage.getItem("token")) {
      props.history.push("/login");
      return;
    }

    if (editingFestival) {
      const updatedFestival = { ...editingFestival, ...festival };

      // Update the festival in the database
      axios
        .put(`${mainURL}/festivals/${editingFestival.id}`, updatedFestival, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setAllFestivals((prevFestivals) =>
            prevFestivals.map((f) =>
              f.id === updatedFestival.id ? updatedFestival : f
            )
          );
          setEditingFestival(null);
          Swal.fire("Success!", "Festival updated successfully", "success");
        })
        .catch((error) => {
          console.error("Error updating festival:", error);
          Swal.fire("Error!", "Failed to update festival", "error");
        });
    } else {
      // Create a new festival in the database
      axios
        .post(`${mainURL}/festivals`, festival, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setAllFestivals((prevFestivals) => [
            ...prevFestivals,
            response.data.data,
          ]);
          Swal.fire("Success!", "Festival created successfully", "success");
        })
        .catch((error) => {
          console.error("Error creating festival:", error);
          Swal.fire("Error!", "Failed to create festival", "error");
        });
    }
  };

  const handleDeleteFestival = (deleteFestival = null) => {
    setShowCreateFestivalModal(false);

    // check if user is authenticated
    if (!localStorage.getItem("token")) {
      props.history.push("/login");
      return;
    }

    const festivalId = deleteFestival ? deleteFestival.id : editingFestival.id;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete the festival from the database
        axios
          .delete(`${mainURL}/festivals/${festivalId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then(() => {
            setAllFestivals((prevFestivals) =>
              prevFestivals.filter((f) => f.id !== festivalId)
            );
            setEditingFestival(null);
            Swal.fire("Success!", "Festival deleted successfully", "success");
          })
          .catch((error) => {
            console.error("Error deleting festival:", error);
            Swal.fire("Error!", "Failed to delete festival", "error");
          });
      }
    });
  };

  return (
    <>
      <div className="dashboard-BG">
        <div className="row searchandDropdown">
          <div className="col-md-9">
            <span className="searchIcon">
              <i className="fa fa-search"></i>
            </span>
            <input
              type="text"
              defaultValue={searchValue !== "" ? searchValue : ""}
              name=""
              placeholder="Search for hosting"
              className="searchbox"
              onChange={(e) => finalfilterdata(e.target.value, "searchData")}
            ></input>
          </div>
          <div className="col-md-3">
            <div className="dropdownContainer">
              <span className="right-dropdown">Status of Listing:</span>
              <Select
                placeholder="Select"
                isSearchable={false}
                options={data}
                onChange={(e) => finalfilterdata(e.value, "statusoflisting")}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.icon}
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <div id="user-wrapper" className="dashboard  content hostingpage">
          <div className="position_absolute">
            {selectedtab !== "4" ? (
              <button
                className="btn menubtn actionBtn"
                onClick={() => gotohosting()}
              >
                + Host Opportunity
              </button>
            ) : (
              <button
                className="btn menubtn actionBtn"
                onClick={() => setShowCreateFestivalModal(true)}
              >
                + Create Festival
              </button>
            )}
            {selectedtab !== "4" && (
              <Select
                placeholder="Sort By"
                isSearchable={false}
                // value={selectedOption}
                options={sortby}
                onChange={(e) => finalfilterdata(e.value, "sortby")}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
              />
            )}
          </div>
          <h3>
            {tempData && tempData.length}{" "}
            {tempData && tempData.length > 1 ? "Hostings" : "Hosting"}
          </h3>
          <Tabs
            style={{
              marginBottom: 32,
            }}
            className="insideTab"
            onChange={(e) => selectetab(e)}
          >
            <TabPane
              tab={
                <span>
                  <img
                    className="list-icon-img"
                    src={require("../../images/all.png").default}
                    alt="od-banner"
                  />{" "}
                  All ({alldata && alldata?.length}){" "}
                </span>
              }
              key="1"
            >
              {alldata?.length > 0 ? (
                alldata?.map((item, index) => (
                  <div className="row registerbox" id={`opportunity${item.id}`}>
                    <div className="col-md-2">
                      <div>
                        <img
                          src={item.logo_image}
                          className="imgsize"
                          alt="logo"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p className="myhosting-titiles">
                        <Link to={`/e/${item.slug}`}>{item.title}</Link>
                        {moment(item.end_date).diff(
                          moment(new Date()),
                          "days"
                        ) <= 0 ? (
                          <button className="btn menubtn stautusBtn closed">
                            <i className="fa fa-lock"></i> Closed
                          </button>
                        ) : (
                          <button className="btn menubtn stautusBtn ">
                            <i className="fa fa-circle"></i> Live
                          </button>
                        )}
                        <i className="fa fa-share-alt shareicon"></i>
                        <a
                          href={"/e/" + item.slug}
                          target="_blank"
                          className="previewlink"
                          rel="noreferrer"
                        >
                          Preview
                        </a>
                        {/* <WhatsappShareButton url="https://www.gradpartners.in/e/HCCB-campus-2023-60">
<WhatsappIcon size={32} round={true} />
</WhatsappShareButton> */}
                      </p>
                      {/* <p>
                    {item.organization}, {item.location}
                  </p> */}

                      {item.rounds[0]?.start_date &&
                      moment(item.rounds[0].start_date).diff(
                        moment(new Date()),
                        "days"
                      ) >= 0 ? (
                        <div className="displayflex1">
                          <div>
                            <p>
                              {" "}
                              Ongoing Round:{" "}
                              <span className="team_name">Registration</span>
                            </p>
                          </div>
                          <div>
                            <p>
                              {" "}
                              Ongoing Round Deadline:{" "}
                              <span className="team_name">
                                <Moment
                                  format="Do MMM YYYY "
                                  date={item.register_end_date}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="displayflex1">
                          <div>
                            <p>
                              {" "}
                              Ongoing Round:{" "}
                              <span className="team_name">
                                {item.rounds.find(
                                  (round) =>
                                    moment(new Date())
                                      .startOf("day")
                                      .isSameOrAfter(
                                        moment(round.start_date).startOf("day")
                                      ) &&
                                    moment(new Date())
                                      .startOf("day")
                                      .isSameOrBefore(
                                        moment(round.end_date).startOf("day")
                                      )
                                )?.title ?? "NA"}
                              </span>
                            </p>
                          </div>
                          {item.rounds.find(
                            (round) =>
                              moment(new Date())
                                .startOf("day")
                                .isSameOrAfter(
                                  moment(round.start_date).startOf("day")
                                ) &&
                              moment(new Date())
                                .startOf("day")
                                .isSameOrBefore(
                                  moment(round.end_date).startOf("day")
                                )
                          )?.end_date && (
                            <div>
                              <p>
                                {" "}
                                Ongoing Round Deadline:{" "}
                                <span className="team_name">
                                  <Moment
                                    format="Do MMM YYYY "
                                    date={
                                      item.rounds.find(
                                        (round) =>
                                          moment(new Date())
                                            .startOf("day")
                                            .isSameOrAfter(
                                              moment(round.start_date).startOf(
                                                "day"
                                              )
                                            ) &&
                                          moment(new Date())
                                            .startOf("day")
                                            .isSameOrBefore(
                                              moment(round.end_date).startOf(
                                                "day"
                                              )
                                            )
                                      )?.end_date
                                    }
                                  />
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                      <p className="mt-10">
                        <FaRegCalendarAlt className="roundcal" />
                        <Moment
                          format="DD MMM YYYY "
                          date={item.start_date}
                        />{" "}
                        - <Moment format="DD MMM YYYY " date={item.end_date} />{" "}
                      </p>
                    </div>
                    <div className="col-md-4 hostingcls talignright">
                      <div className="d-flex justify-content-end">
                        <Popconfirm
                          title="Are you sure to delete this Opportunity?"
                          onConfirm={() => deleteOpp(item.id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <p className="mt-10">Delete</p>
                        </Popconfirm>

                        <button
                          className="btn menubtn actionBtn transparentbtn"
                          onClick={() => gotoEdithosting(item.id)}
                        >
                          <i
                            className="fa fa-pencil-square-o"
                            aria-hidden="true"
                          ></i>{" "}
                          &nbsp;Edit Opportunity
                        </button>
                      </div>
                      <div className="d-flex justify-content-end">
                        <p className="mt-10" onClick={() => duplicate(item.id)}>
                          Duplicate
                        </p>
                        <button
                          className="btn menubtn actionBtn transparentbtn"
                          // disabled
                          onClick={() => gotodashboard(item.id)}
                        >
                          <img
                            src={
                              require("../../images/view_dashboard.png").default
                            }
                            alt="od-banner"
                            className="dashboardicon"
                          />{" "}
                          &nbsp; View Dashboard
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <img
                    class="img-fluid"
                    src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png"
                    alt="not found"
                  />
                </div>
              )}
            </TabPane>
            <TabPane
              tab={
                <span>
                  {" "}
                  <img
                    className="list-icon-img"
                    src={require("../../images/Icon-Competition.png").default}
                    alt="od-banner"
                  />{" "}
                  Competitions (
                  {alldata &&
                    alldata.reduce(function (n, person) {
                      return n + (person.category === "opportunity");
                    }, 0)}
                  ){" "}
                </span>
              }
              key="2"
            >
              {alldata.length > 0 ? (
                alldata.map(
                  (item, index) =>
                    item.category === "opportunity" && (
                      <div className="row registerbox">
                        <div className="col-md-2">
                          <div>
                            <img
                              src={item.logo_image}
                              className="imgsize"
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="myhosting-titiles">
                            <Link to={`/e/${item.slug}`}>{item.title}</Link>
                            {moment(item.end_date).diff(
                              moment(new Date()),
                              "days"
                            ) <= 0 ? (
                              <button className="btn menubtn stautusBtn closed">
                                <i className="fa fa-lock"></i> Closed
                              </button>
                            ) : (
                              <button className="btn menubtn stautusBtn ">
                                <i className="fa fa-circle"></i> Live
                              </button>
                            )}
                            <i className="fa fa-share-alt shareicon"></i>
                            {/* <WhatsappShareButton url="https://www.gradpartners.in/e/HCCB-campus-2023-60">
  <WhatsappIcon size={32} round={true} />
  </WhatsappShareButton> */}
                          </p>
                          {/* <p>
                      {item.organization}, {item.location}
                    </p> */}

                          {moment(item.rounds[0]?.start_date).diff(
                            moment(new Date()),
                            "days"
                          ) >= 0 ? (
                            <div className="displayflex1">
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round:{" "}
                                  <span className="team_name">
                                    Registration
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round Deadline:{" "}
                                  <span className="team_name">
                                    <Moment
                                      format="Do MMM YYYY "
                                      date={item.register_end_date}
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="displayflex1">
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round:{" "}
                                  <span className="team_name">
                                    {item.rounds.find(
                                      (round) =>
                                        moment(new Date())
                                          .startOf("day")
                                          .isSameOrAfter(
                                            moment(round.start_date).startOf(
                                              "day"
                                            )
                                          ) &&
                                        moment(new Date())
                                          .startOf("day")
                                          .isSameOrBefore(
                                            moment(round.end_date).startOf(
                                              "day"
                                            )
                                          )
                                    )?.title ?? "NA"}
                                  </span>
                                </p>
                              </div>
                              {item.rounds.find(
                                (round) =>
                                  moment(new Date())
                                    .startOf("day")
                                    .isSameOrAfter(
                                      moment(round.start_date).startOf("day")
                                    ) &&
                                  moment(new Date())
                                    .startOf("day")
                                    .isSameOrBefore(
                                      moment(round.end_date).startOf("day")
                                    )
                              )?.end_date && (
                                <div>
                                  <p>
                                    {" "}
                                    Ongoing Round Deadline:{" "}
                                    <span className="team_name">
                                      <Moment
                                        format="Do MMM YYYY "
                                        date={
                                          item.rounds.find(
                                            (round) =>
                                              moment(new Date())
                                                .startOf("day")
                                                .isSameOrAfter(
                                                  moment(
                                                    round.start_date
                                                  ).startOf("day")
                                                ) &&
                                              moment(new Date())
                                                .startOf("day")
                                                .isSameOrBefore(
                                                  moment(
                                                    round.end_date
                                                  ).startOf("day")
                                                )
                                          )?.end_date
                                        }
                                      />
                                    </span>
                                  </p>
                                </div>
                              )}
                            </div>
                          )}

                          <p className="mt-10">
                            <FaRegCalendarAlt className="roundcal" />
                            <Moment
                              format="DD MMM YYYY "
                              date={item.start_date}
                            />{" "}
                            -{" "}
                            <Moment
                              format="DD MMM YYYY "
                              date={item.end_date}
                            />{" "}
                          </p>
                        </div>
                        <div className="col-md-4 hostingcls talignright">
                          <div className="d-flex justify-content-end">
                            <p className="mt-10">Preview</p>
                            <button
                              className="btn menubtn actionBtn transparentbtn"
                              onClick={() => gotoEdithosting(item.id)}
                            >
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>{" "}
                              &nbsp;Edit Opportunity
                            </button>
                          </div>
                          <div className="d-flex justify-content-end">
                            <p className="mt-10">Duplicate</p>
                            <button
                              className="btn menubtn actionBtn transparentbtn"
                              // disabled
                              onClick={() => gotodashboard(item.id)}
                            >
                              <img
                                src={
                                  require("../../images/view_dashboard.png")
                                    .default
                                }
                                alt="od-banner"
                                className="dashboardicon"
                              />{" "}
                              &nbsp; View Dashboard
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="text-center">
                  <img
                    class="img-fluid"
                    src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png"
                    alt="Not Found"
                  />
                </div>
              )}
            </TabPane>
            <TabPane
              tab={
                <span>
                  {" "}
                  <img
                    className="list-icon-img"
                    src={require("../../images/Icon-Workshop.png").default}
                    alt="od-banner"
                  />{" "}
                  Workshops (
                  {alldata &&
                    alldata.reduce(function (n, person) {
                      return n + (person.category === "workshop");
                    }, 0)}
                  ){" "}
                </span>
              }
              key="3"
            >
              {alldata.length > 0 ? (
                alldata.map(
                  (item, index) =>
                    item.category === "workshop" && (
                      <div className="row registerbox">
                        <div className="col-md-2">
                          <div>
                            <img
                              src={item.logo_image}
                              alt="logo"
                              className="imgsize"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="myhosting-titiles">
                            <Link to={`/e/${item.slug}`}>{item.title}</Link>
                            {moment(item.end_date).diff(
                              moment(new Date()),
                              "days"
                            ) <= 0 ? (
                              <button className="btn menubtn stautusBtn closed">
                                <i className="fa fa-lock"></i> Closed
                              </button>
                            ) : (
                              <button className="btn menubtn stautusBtn ">
                                <i className="fa fa-circle"></i> Live
                              </button>
                            )}
                            <i className="fa fa-share-alt shareicon"></i>
                            {/* <WhatsappShareButton url="https://www.gradpartners.in/e/HCCB-campus-2023-60">
  <WhatsappIcon size={32} round={true} />
  </WhatsappShareButton> */}
                          </p>
                          {/* <p>
                      {item.organization}, {item.location}
                    </p> */}

                          {moment(item.rounds[0]?.start_date).diff(
                            moment(new Date()),
                            "days"
                          ) >= 0 ? (
                            <div className="displayflex1">
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round:{" "}
                                  <span className="team_name">
                                    Registration
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round Deadline:{" "}
                                  <span className="team_name">
                                    <Moment
                                      format="Do MMM YYYY "
                                      date={item.register_end_date}
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="displayflex1">
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round:{" "}
                                  <span className="team_name">
                                    {item.rounds.find(
                                      (round) =>
                                        moment(new Date())
                                          .startOf("day")
                                          .isSameOrAfter(
                                            moment(round.start_date).startOf(
                                              "day"
                                            )
                                          ) &&
                                        moment(new Date())
                                          .startOf("day")
                                          .isSameOrBefore(
                                            moment(round.end_date).startOf(
                                              "day"
                                            )
                                          )
                                    )?.title ?? "NA"}
                                  </span>
                                </p>
                              </div>
                              {item.rounds.find(
                                (round) =>
                                  moment(new Date())
                                    .startOf("day")
                                    .isSameOrAfter(
                                      moment(round.start_date).startOf("day")
                                    ) &&
                                  moment(new Date())
                                    .startOf("day")
                                    .isSameOrBefore(
                                      moment(round.end_date).startOf("day")
                                    )
                              )?.end_date && (
                                <div>
                                  <p>
                                    {" "}
                                    Ongoing Round Deadline:{" "}
                                    <span className="team_name">
                                      <Moment
                                        format="Do MMM YYYY "
                                        date={
                                          item.rounds.find(
                                            (round) =>
                                              moment(new Date())
                                                .startOf("day")
                                                .isSameOrAfter(
                                                  moment(
                                                    round.start_date
                                                  ).startOf("day")
                                                ) &&
                                              moment(new Date())
                                                .startOf("day")
                                                .isSameOrBefore(
                                                  moment(
                                                    round.end_date
                                                  ).startOf("day")
                                                )
                                          )?.end_date
                                        }
                                      />
                                    </span>
                                  </p>
                                </div>
                              )}
                            </div>
                          )}

                          <p className="mt-10">
                            <FaRegCalendarAlt className="roundcal" />
                            <Moment
                              format="DD MMM YYYY "
                              date={item.start_date}
                            />{" "}
                            -{" "}
                            <Moment
                              format="DD MMM YYYY "
                              date={item.end_date}
                            />{" "}
                          </p>
                        </div>
                        <div className="col-md-4 hostingcls talignright">
                          <div className="d-flex justify-content-end">
                            <p className="mt-10">
                              <a
                                href={"/e/" + item.slug}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Preview
                              </a>
                            </p>
                            <button
                              className="btn menubtn actionBtn transparentbtn"
                              onClick={() => gotoEdithosting(item.id)}
                            >
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>{" "}
                              &nbsp;Edit Opportunity
                            </button>
                          </div>
                          <div className="d-flex justify-content-end">
                            <p className="mt-10">Duplicate</p>
                            <button
                              className="btn menubtn actionBtn transparentbtn"
                              // disabled
                              onClick={() => gotodashboard(item.id)}
                            >
                              <img
                                src={
                                  require("../../images/view_dashboard.png")
                                    .default
                                }
                                alt="od-banner"
                                className="dashboardicon"
                              />{" "}
                              &nbsp; View Dashboard
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="text-center">
                  <img
                    class="img-fluid"
                    src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png"
                    alt="not found"
                  />
                </div>
              )}
            </TabPane>
            <TabPane
              tab={
                <span>
                  {" "}
                  <img
                    className="list-icon-img"
                    src={require("../../images/Icon-Workshop.png").default}
                    alt="od-banner"
                  />{" "}
                  Festivals ({allFestivals.length}){" "}
                </span>
              }
              key="4"
            >
              <FestivalList
                festivals={allFestivals}
                onEdit={(festival) => {
                  setEditingFestival(festival);
                  setShowCreateFestivalModal(true);
                }}
                onDelete={handleDeleteFestival}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>

      {showCreateFestivalModal && (
        <CreateFestivalModal
          editingFestival={editingFestival}
          onClose={() => {
            setEditingFestival(null);
            setShowCreateFestivalModal(false);
          }}
          onCreateFestival={handleCreateOrUpdateFestival}
          onDeleteFestival={handleDeleteFestival}
        />
      )}
    </>
  );
};

export default Userhosting;
