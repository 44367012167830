import React from "react";
import CKEditor from "react-ckeditor-component";
import { debounce } from "lodash";

export const CKEditorConfig = {
  toolbar: [
    { name: "styles", items: ["Format"] },
    {
      name: "basicstyles",
      items: ["Bold", "Italic", "Strike"],
    },
    {
      name: "paragraph",
      items: ["NumberedList", "BulletedList", "Blockquote"],
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "editing", items: ["Undo", "Cut", "Copy", "Paste"] },
  ],
  extraPlugins: "undo,link,list,blockquote,format",
  removePlugins: "flash,forms",
  height: "150px",
};

function FestivalDetailsEditor(props) {
  const handleEditorChange = debounce((data) => {
    props.OnFestivalDetailsChange(data);
  }, 300); // Adjust the debounce time as needed
  return (
    <div>
      <CKEditor
        id="cke_editor1"
        content={props.festivalDetails}
        config={CKEditorConfig}
        events={{
          change: (e) => {
            const data = e.editor.getData();
            handleEditorChange(data);
          },
        }}
      />
    </div>
  );
}

export default FestivalDetailsEditor;
