import React, { useEffect, useState } from "react";
import { IoMdCreate, IoMdTrash } from "react-icons/io";
import { RiFileInfoLine } from "react-icons/ri";
import CustomFieldModal from "../../DynamicPages/FormSteps/CustomFieldModal";
import "./submissionSettings.css";

function DynamicSubmissionForm({
  index,
  allowedFileTypes,
  submissionFormSettings,
  onSubFormSettingsChange,
}) {
  const [editingField, setEditingField] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  // This is to open the links (if any added by user) in the tnc field in a new tab
  useEffect(() => {
    // Find all .tnc-text containers
    const containers = document.querySelectorAll(".tnc-text");
    containers.forEach((container) => {
      const links = container.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      });
    });
  }, [submissionFormSettings]);

  const renderField = (field) => {
    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
        return (
          <input
            className="form-control"
            type={field.fieldType}
            name={field.fieldName}
            placeholder={field.placeholder}
          />
        );
      case "radio":
        return (
          <div className="radio-field">
            {field.options.map((option, index) => (
              <label key={index}>
                <input type="radio" name={field.fieldName} value={option} />
                {option}
              </label>
            ))}
          </div>
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            name={field.fieldName}
            placeholder={field.placeholder}
            maxLength={field.rules?.find((rule) => rule.maxLength)?.maxLength}
          />
        );
      case "checkbox":
        return (
          <div className="custom-field-checkbox-container">
            {field.options.map((option, index) => (
              <label key={index} className="custom-field-checkbox-item">
                <input
                  type="checkbox"
                  name={field.fieldName}
                  value={option}
                  className="form-control custom-field-checkbox"
                />
                {field.isTnCField ? (
                  <div className="tnc-text">
                    <div dangerouslySetInnerHTML={{ __html: option }} />
                  </div>
                ) : (
                  option
                )}
              </label>
            ))}
          </div>
        );

      case "select":
        return (
          <select className="form-control" name={field.fieldName}>
            {field.fieldName !== "institute_organization" &&
              field.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
          </select>
        );
      case "file":
        return (
          <input className="form-control" type="file" name={field.fieldName} />
        );
      default:
        return null;
    }
  };

  const handleSaveField = (newField) => {
    if (editingField) {
      onSubFormSettingsChange(
        submissionFormSettings.map((field) => {
          if (field.fieldName === editingField.fieldName) {
            return newField;
          }
          return field;
        })
      );
      setEditingField(null);
      return;
    } else {
      onSubFormSettingsChange([...submissionFormSettings, newField]);
      setEditingField(null);
    }
  };

  return (
    <div className="form-body create-submission-form">
      <div className="create-submission-form-header">
        <label className="create-submission-form-label">
          Create Submission Form
        </label>

        <button
          className="add-field-button btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            setModalOpen(true);
          }}
        >
          + Add Custom Field
        </button>
      </div>
      {submissionFormSettings.length ? (
        submissionFormSettings.map((item, index) => {
          return (
            <div className="input-field" key={index}>
              <div className="field-label-container">
                <label>
                  {item.label}
                  {item.isMandatory ? (
                    <span className="required-mark"> *</span>
                  ) : (
                    ""
                  )}
                </label>

                <IoMdCreate
                  className="delete-field-icon"
                  title="Edit Custom Field"
                  onClick={() => {
                    setEditingField(item);
                    setModalOpen(true);
                  }}
                />

                <IoMdTrash
                  className="delete-field-icon"
                  title="Delete Custom Field"
                  onClick={() => {
                    const array = submissionFormSettings.concat();
                    array.splice(index, 1);
                    onSubFormSettingsChange(array);
                  }}
                />
              </div>
              {renderField(item)}
            </div>
          );
        })
      ) : (
        <div className="no-custom-field">
          <RiFileInfoLine size={50} color="#b5b5b5" />
          <p>Start Adding Custom Fields</p>
        </div>
      )}

      <CustomFieldModal
        allColleges={[]}
        allowedFileTypes={allowedFileTypes}
        isOpen={isModalOpen}
        isSubmissionSettings={true}
        editingField={editingField}
        existingFields={submissionFormSettings}
        onClose={() => {
          setModalOpen(false);
          setEditingField(null);
        }}
        onSave={handleSaveField}
      />
    </div>
  );
}

export default DynamicSubmissionForm;
